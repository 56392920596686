/* eslint-disable import/no-anonymous-default-export */
import {
  Briefcase as BriefcaseIcon,
  // Lock as LockIcon,
  // UserPlus as UserPlusIcon,
  Shield as ShieldIcon,
  User as UserIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  MapPin as MapIcon,
  HelpCircle as HelpCircleIcon,
  MessageCircle as MessageCircleIcon,
} from 'react-feather';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';

export default [
  {
    subheader: 'Management',

    items: [
      {
        title: 'Statistics',
        icon: ReceiptIcon,
        href: '/statistics',
      },
      {
        title: 'Tickets',
        icon: ReceiptIcon,
        href: '/',
      },
      {
        title: 'Users',
        icon: UsersIcon,
        href: '/users',
      },
      {
        title: 'Employees',
        icon: BriefcaseIcon,
        href: '/employees',
      },
      {
        title: 'Roles',
        icon: ShieldIcon,
        href: '/roles',
      },
      {
        title: 'Chats',
        icon: MessageCircleIcon,
        href: '/chats',
      },
      {
        title: 'Live Chat',
        icon: MessageCircleIcon,
        href: '/liveChat',
      },
      // {
      //   title: "Clients",
      //   icon: UsersIcon,
      //   href: "/clients",
      // },
      // {
      //   title: "Roles",
      //   icon: ShieldIcon,
      //   items: [
      //     {
      //       title: "List Roles",
      //       href: "/management/roles",
      //     },
      //   ],
      // },
    ],
  },
  {
    subheader: 'Ibs Statics Information',
    items: [
      {
        title: 'Contact Us',
        href: '/statics/contactUs',
        icon: ShareIcon,
      },
      {
        title: 'About Us',
        href: '/statics/aboutUs',
        icon: BriefcaseIcon,
      },
      {
        title: 'Visit Us',
        href: '/statics/visitUs',
        icon: MapIcon,
      },
    ],
  },
  {
    subheader: 'Others',
    items: [
      {
        title: 'FAQ',
        href: '/faq/',
        icon: HelpCircleIcon,
      },
      {
        title: 'Information',
        href: '/information/',
        icon: HelpCircleIcon,
      },
      {
        title: 'Profile',
        href: '/profile/',
        icon: UserIcon,
      },
    ],
  },
  // {
  //   subheader: "Auth",
  //   items: [
  //     {
  //       title: "Login",
  //       href: "/auth/login",
  //       icon: LockIcon,
  //     },
  //     {
  //       title: "Register",
  //       href: "/auth/register",
  //       icon: UserPlusIcon,
  //     },
  //   ],
  // },
];
