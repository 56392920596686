const restoreStorage = (item, json = true) => {
  let data = null;

  try {
    const storedData = localStorage.getItem(item);
    if (storedData && json) {
      data = JSON.parse(storedData);
    }
  } catch (err) {
    // If stored data is not a strigified JSON this might fail,
    // that's why we catch the error
  }

  return data;
};

const storeStorage = (item, data, json = true) => {
  localStorage.setItem(item, json ? JSON.stringify(data) : data);
};

const removeStorage = (item) => {
  localStorage.removeItem(item);
};

const clearStorageAuth = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('access_token');
};

export { storeStorage, removeStorage, clearStorageAuth, restoreStorage };
