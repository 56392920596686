import { useContext } from 'react';
import UserContext from 'src/context/UserContext';
import { restoreStorage } from 'src/utils/storage';

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default () => {
  const context = useContext(UserContext);
  context.user = restoreStorage('user');

  return context;
};
