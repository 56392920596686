import axios from 'axios';

const HTTP = axios.create({
  baseURL: 'https://api.ibsns.com/v1/',
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en',
  },
  // withCredentials: true,
});

HTTP.interceptors.request.use(
  (res) => {
    return Promise.resolve(res);
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default HTTP;
