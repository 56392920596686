import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { storeStorage } from 'src/utils/storage';

const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);

  const saveUser = (user) => {
    setCurrentUser(user);
    storeStorage('user', user);
  };

  return <UserContext.Provider value={{ user: currentUser, saveUser }}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const UserConsumer = UserContext.Consumer;

export default UserContext;
